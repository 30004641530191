import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CardMatchRecordsComponent } from '../shared/card-match-records/card-match-records.component';

@Component({
  selector: 'app-live-match-card',
  standalone: true,
  imports: [CommonModule, CardMatchRecordsComponent],
  templateUrl: './live-match-card.component.html',
  styleUrl: '../../fixtures.css',
})
export class LiveMatchCardComponent {
  @Input() match!: any;

  @Input() latestMatchId!: any;
  getElapsedMinute(scoreTime: Date, gameStartTime: Date): string {
    // Calculate the difference in milliseconds
    gameStartTime = new Date(gameStartTime); // Game starts at 3 PM
    scoreTime = new Date(scoreTime); // Game starts at 3 PM

    const elapsedMilliseconds = scoreTime.getTime() - gameStartTime.getTime();
    // Convert to minutes
    const elapsedMinutes = Math.floor(elapsedMilliseconds / (1000 * 60));

    // Return formatted minute with a single quote
    return `${elapsedMinutes}'`;
  }
}
