import { Component, HostListener } from '@angular/core';
import { SearchFixturesComponent } from './search-fixtures/search-fixtures.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [SearchFixturesComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
})
export class HeaderComponent {
  activeSection: string = ''; // Track the active section

  constructor() {
    // Retrieve the active section from localStorage on initialization
    const storedSection = localStorage.getItem('activeSection');
    if (storedSection) {
      this.activeSection = storedSection;
      this.scrollTo(this.activeSection); // Scroll to the saved section
    }
  }
  scrollTo(section: string) {
    console.log(section);

    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      localStorage.setItem('activeSection', section);
    }
  }
  setActive(section: string) {
    this.activeSection = section; // Update the active section
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const sections = document.querySelectorAll('.tab-content > div');
    sections.forEach((section) => {
      const rect = section.getBoundingClientRect();
      if (rect.top >= 0 && rect.top < window.innerHeight) {
        this.activeSection = section.id; // Update the active section based on scroll position
        localStorage.setItem('activeSection', this.activeSection); // Save the active section to localStorage
      }
    });
  }
}
