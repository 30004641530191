<div class="">
  <!-- WEEKS / SMALL DEVICE -->
  <div class="mx-auto" style="padding-bottom: 20px;">
    <!-- WEEKS COL -->
    <div class="d-flex justify-content-center">
      <div class="d-flex" style="margin: 5px; padding: 5px">
        <input class="input-inset" type="text" placeholder="Search fixtures" (keyup)="searchByName($event)" />
        <div
          style="float: right; margin-left: 1vh; margin-top: 1vh"
          bsDatepicker
          (bsValueChange)="onValueChangeForm($event)"
          [(bsValue)]="todayDate"
          [bsConfig]="bsconfig"
          placement="left"
        >
          <svg
            height="28px"
            width="28px"
            fill="#FFC200"
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 610.398 610.398"
            xml:space="preserve"
            stroke="#FFC200"
            stroke-width="0.00610398"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <g>
                <g>
                  <path
                    d="M159.567,0h-15.329c-1.956,0-3.811,0.411-5.608,0.995c-8.979,2.912-15.616,12.498-15.616,23.997v10.552v27.009v14.052 c0,2.611,0.435,5.078,1.066,7.44c2.702,10.146,10.653,17.552,20.158,17.552h15.329c11.724,0,21.224-11.188,21.224-24.992V62.553 V35.544V24.992C180.791,11.188,171.291,0,159.567,0z"
                  ></path>
                  <path
                    d="M461.288,0h-15.329c-11.724,0-21.224,11.188-21.224,24.992v10.552v27.009v14.052c0,13.804,9.5,24.992,21.224,24.992 h15.329c11.724,0,21.224-11.188,21.224-24.992V62.553V35.544V24.992C482.507,11.188,473.007,0,461.288,0z"
                  ></path>
                  <path
                    d="M539.586,62.553h-37.954v14.052c0,24.327-18.102,44.117-40.349,44.117h-15.329c-22.247,0-40.349-19.79-40.349-44.117 V62.553H199.916v14.052c0,24.327-18.102,44.117-40.349,44.117h-15.329c-22.248,0-40.349-19.79-40.349-44.117V62.553H70.818 c-21.066,0-38.15,16.017-38.15,35.764v476.318c0,19.784,17.083,35.764,38.15,35.764h468.763c21.085,0,38.149-15.984,38.149-35.764 V98.322C577.735,78.575,560.671,62.553,539.586,62.553z M527.757,557.9l-446.502-0.172V173.717h446.502V557.9z"
                  ></path>
                  <path
                    d="M353.017,266.258h117.428c10.193,0,18.437-10.179,18.437-22.759s-8.248-22.759-18.437-22.759H353.017 c-10.193,0-18.437,10.179-18.437,22.759C334.58,256.074,342.823,266.258,353.017,266.258z"
                  ></path>
                  <path
                    d="M353.017,348.467h117.428c10.193,0,18.437-10.179,18.437-22.759c0-12.579-8.248-22.758-18.437-22.758H353.017 c-10.193,0-18.437,10.179-18.437,22.758C334.58,338.288,342.823,348.467,353.017,348.467z"
                  ></path>
                  <path
                    d="M353.017,430.676h117.428c10.193,0,18.437-10.18,18.437-22.759s-8.248-22.759-18.437-22.759H353.017 c-10.193,0-18.437,10.18-18.437,22.759S342.823,430.676,353.017,430.676z"
                  ></path>
                  <path
                    d="M353.017,512.89h117.428c10.193,0,18.437-10.18,18.437-22.759c0-12.58-8.248-22.759-18.437-22.759H353.017 c-10.193,0-18.437,10.179-18.437,22.759C334.58,502.71,342.823,512.89,353.017,512.89z"
                  ></path>
                  <path
                    d="M145.032,266.258H262.46c10.193,0,18.436-10.179,18.436-22.759s-8.248-22.759-18.436-22.759H145.032 c-10.194,0-18.437,10.179-18.437,22.759C126.596,256.074,134.838,266.258,145.032,266.258z"
                  ></path>
                  <path
                    d="M145.032,348.467H262.46c10.193,0,18.436-10.179,18.436-22.759c0-12.579-8.248-22.758-18.436-22.758H145.032 c-10.194,0-18.437,10.179-18.437,22.758C126.596,338.288,134.838,348.467,145.032,348.467z"
                  ></path>
                  <path
                    d="M145.032,430.676H262.46c10.193,0,18.436-10.18,18.436-22.759s-8.248-22.759-18.436-22.759H145.032 c-10.194,0-18.437,10.18-18.437,22.759S134.838,430.676,145.032,430.676z"
                  ></path>
                  <path
                    d="M145.032,512.89H262.46c10.193,0,18.436-10.18,18.436-22.759c0-12.58-8.248-22.759-18.436-22.759H145.032 c-10.194,0-18.437,10.179-18.437,22.759C126.596,502.71,134.838,512.89,145.032,512.89z"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
          <svg
            height="28px"
            width="28px"
            viewBox="-2.4 -2.4 28.80 28.80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#FFC200"
            stroke-width="0.00024000000000000003"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke="#CCCCCC"
              stroke-width="0.192"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.7071 14.7071C12.3166 15.0976 11.6834 15.0976 11.2929 14.7071L6.29289 9.70711C5.90237 9.31658 5.90237 8.68342 6.29289 8.29289C6.68342 7.90237 7.31658 7.90237 7.70711 8.29289L12 12.5858L16.2929 8.29289C16.6834 7.90237 17.3166 7.90237 17.7071 8.29289C18.0976 8.68342 18.0976 9.31658 17.7071 9.70711L12.7071 14.7071Z"
                fill="#FFC200"
              ></path>
            </g>
          </svg>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center">
    <div
      id="fixtures-col"
      class="col-md-7 col-12"
      style="border: 1px solid #ffc200"
    >
      <!-- FIXTURES COL -->

      <div class="accordion" id="fixtureaccordion">
        <!-- FINISHED MATCHES -> btn-accordion-finished -->
        @if (allMatches.length > 0) { @for (mainMatch of allMatches; track
        $index){
        <div class="daytitle">
          {{ mainMatch?.date | date : "EEEE, MMMM d, y" }} (
          {{ mainMatch?.matches.length }} Matches)
        </div>
        <hr />
        @for (match of mainMatch.matches; track $index) { @if (match.status ==
        'upcoming') {
        <app-upcoming-match-card
          [match]="match"
          [latestMatchId]="latestMatchId"
        ></app-upcoming-match-card>
        <hr />
        }@else if (match.status == 'live') {

        <app-live-match-card
          [match]="match"
          [latestMatchId]="latestMatchId"
        ></app-live-match-card>
        <hr />
        }@else if (match.status == 'completed') {

        <app-completed-match-card
          [match]="match"
          [latestMatchId]="latestMatchId"
        ></app-completed-match-card>
        <hr />
        } } } }@else {
        <h1 style="padding-top: 9vh">Sakaja Super Cup 2024 Edition</h1>
        <h4>17 Sub-Counties Over 700 Teams!</h4>
        }

        <span *ngFor="let match of allMatches"> </span>
      </div>
    </div>

    <!-- WEEKS / BIG DEVICE -->
  </div>
</div>
