<div id="loading-area" class="loading-page-1">
  <div class="spinner">
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="7" stroke-width="1" />
    </svg>
  </div>
</div>
<div class="page-wraper">
  <app-header></app-header>

  <router-outlet></router-outlet>

  <app-footer></app-footer>
  <!-- scroll top button -->
  <button class="scroltop fa fa-caret-up"></button>
</div>
