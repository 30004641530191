import { Component, OnInit } from '@angular/core';
import { FixturespageComponent } from '../fixtures/fixtures';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-homepage',
  standalone: true,
  imports: [
    RouterModule,
    FixturespageComponent,
    FormsModule,
    CommonModule,
    HttpClientModule,
    BsDatepickerModule,
  ],
  templateUrl: './homepage.component.html',
  styleUrl: './homepage.component.css',
})
export class HomepageComponent implements OnInit {
  sanitizedUrl!: any;
  randomUpcomingMatches: any[] = [
    {
      id: 1,
      teamA: 'Kariobangi Sports fc',
      teamB: 'Baghdad',
      date: '',
      location: 'Nairobi',
      status: 'upcoming',
    },
    {
      id: 2,
      teamA: 'Mbotela Kamaliza',
      teamB: 'KICK off to Hope fcBurma fc',
      date: '',
      location: 'Nairobi',
      status: 'upcoming',
    },
    {
      id: 3,
      teamA: 'South B all Stars fc',
      teamB: 'Kawasaki fc ',
      date: '',
      location: 'Nairobi',
      status: 'upcoming',
    },
    {
      id: 4,
      teamA: 'Shauri Moyo United',
      teamB: 'Amazon utd',
      date: '',
      location: 'Nairobi',
      status: 'upcoming',
    },
  ]
  constructor(private domSanitizer: DomSanitizer) {}
  ngOnInit(): void {
    this.sanitizedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
      'https://news.sakajasupercup.com/'
    );
  }
}
