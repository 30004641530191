<div class="extra-nav">
  <div class="extra-cell">
    <button id="quik-search-btn" type="button" class="site-button radius-xl">
      <i class="fa fa-search"></i>
    </button>
  </div>
</div>
<!-- Quik search -->
<div class="dez-quik-search bg-primary-dark">
  <form action="index.html#">
    <input
      name="search"
      value=""
      type="text"
      class="form-control"
      placeholder="Type to  (fixtures)"
    />
    <span id="quik-search-remove"><i class="fa fa-remove"></i></span>
  </form>
</div>
