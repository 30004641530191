import { Routes } from '@angular/router';
import { MainComponent } from './components/main/main.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';

export const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: '',
    component: MainComponent,
    children: [
      { path: '', component: HomepageComponent },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
    ],
  },

  { path: '**', redirectTo: '' },
];
