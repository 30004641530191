<div class="accordion-item mb-2">
  <h2 class="accordion-header" [id]="'heading' + match?.xid">
    <button
      class="btn btn-accordion-live collapsed font-rowdies w-100"
      type="button"
      data-bs-toggle="collapse"
      [attr.data-bs-target]="'#collapse' + match?.xid"
      [attr.aria-expanded]="match?.xid === latestMatchId ? 'true' : 'false'"
      [class.collapsed]="match?.xid !== latestMatchId"
      aria-expanded="false"
      [attr.aria-controls]="'collapse' + match?.xid"
    >
      <div class="row">
        <div class="col">{{ match?.sk_teamA?.name }}</div>
        <div class="col-auto">
          <span class="badge border mx-2"

            >{{ match?.team_a_score }} - {{ match?.team_b_score }}
          </span>

          <!-- <span class="badge border mx-2">2 - 0</span> -->
        </div>
        <div class="col">{{ match?.sk_teamB?.name }}</div>
      </div>
    </button>
  </h2>
  <div
    [id]="'collapse' + match?.xid"
    class="accordion-collapse collapse"
    [attr.aria-labelledby]="'heading' + match?.xid"
    [class.show]="match?.xid === latestMatchId"
    data-bs-parent="#fixtureaccordion"
  >
    <div class="accordion-body">
      <span
        class="badge rounded-bottom btn-accordion-live"
        style="background-color: green !important"
        >LIVE
      </span>

      <div class="accordion-content">
        @for (live_update of match?.live_updates; track $index) {
        <app-card-match-records [record]="live_update"></app-card-match-records>
        }
      </div>
    </div>
  </div>
</div>
