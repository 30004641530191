import { Component, OnInit } from '@angular/core';
import { FixturespageComponent } from '../fixtures/fixtures';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-homepage',
  standalone: true,
  imports: [
    RouterModule,
    FixturespageComponent,
    FormsModule,
    CommonModule,
    HttpClientModule,
    BsDatepickerModule,
  ],
  templateUrl: './homepage.component.html',
  styleUrl: './homepage.component.css',
})
export class HomepageComponent implements OnInit {
  sanitizedUrl!: any;
randomUpcomingMatches: any[] = [
  {
    id: 1,
    teamA: 'Kasarani fc',
    teamB: 'Sakaja Super Cup',
    date: '2022-06-01',
    location: 'Nairobi',
    status: 'upcoming',
  },
  {
    id: 2,
    teamA: 'Amazon United',
    teamB: 'Burma fc',
    date: '2022-06-02',
    location: 'Nairobi',
    status: 'upcoming',
  },
  {
    id: 3,
    teamA: 'Pumwani Sports Club',
    teamB: 'Yach 009',
    date: '2022-06-03',
    location: 'Nairobi',
    status: 'upcoming',
  },
  {
    id: 4,
    teamA: 'Wanyiji Youth fc',
    teamB: 'K.M. JUA-KALI',
    date: '2022-06-04',
    location: 'Nairobi',
    status: 'upcoming',
  },
  {
    id: 5,
    teamA: 'FC Highrise',
    teamB: 'Young Gunners',
    date: '2022-06-05',
    location: 'Nairobi',
    status: 'upcoming',
  },
  {
    id: 6,
    teamA: 'Egesa FC',
    teamB: 'Wenyeji UTD',
    date: '2022-06-06',
    location: 'Nairobi',
    status: 'upcoming',
  },
  {
    id: 7,
    teamA: 'Shauri Moyo UTD',
    teamB: 'Fearl FC',
    date: '2022-06-07',
    location: 'Nairobi',
    status: 'upcoming',
  },
  {
    id: 8,
    teamA: 'Kapsambu',
    teamB: 'Mashimoni FC',
    date: '2022-06-08',
    location: 'Nairobi',
    status: 'upcoming',
  },
  {
    id: 9,
    teamA: 'Rada sports',
    teamB: 'Telaviv Sports',
    date: '2022-06-09',
    location: 'Nairobi',
    status: 'upcoming',
  },
  {
    id: 10,
    teamA: 'Sports connect',
    teamB: 'Buruburu Sports',
    date: '2022-06-10',
    location: 'Nairobi',
    status: 'upcoming',
  },
  {
    id: 11,
    teamA: 'Jericho SDA',
    teamB: 'Mutindwa Market FC',
    date: '2022-06-11',
    location: 'Nairobi',
    status: 'upcoming',
  },
  {
    id: 12,
    teamA: 'White Stars FC',
    teamB: 'Young Boys',
    date: '2022-06-12',
    location: 'Nairobi',
    status: 'upcoming',
  },
  {
    id: 13,
    teamA: 'Rova FC',
    teamB: 'Lolly Soccer',
    date: '2022-06-13',
    location: 'Nairobi',
    status: 'upcoming',
  },
  {
    id: 14,
    teamA: 'Gor Mahia Youth',
    teamB: 'Amazon United',
    date: '2022-06-14',
    location: 'Nairobi',
    status: 'upcoming',
  },
];
  constructor(private domSanitizer: DomSanitizer) {}
  ngOnInit(): void {
    this.sanitizedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
      'https://news.sakajasupercup.com/'
    );
  }
}
