<div class="accordion-item">
  <h2 class="accordion-header" [id]="'heading' + match?.xid">
    <button
      class="btn btn-accordion collapsed font-rowdies w-100"
      type="button"
      data-bs-toggle="collapse"
      [attr.data-bs-target]="'#collapse' + match?.xid"
      [attr.aria-expanded]="match?.xid === latestMatchId ? 'true' : 'false'"
      [class.collapsed]="match?.xid !== latestMatchId"
      aria-expanded="false"
      [attr.aria-controls]="'collapse' + match?.xid"
    >
      <div class="row">
        <div class="col">{{ match?.sk_teamA?.name }}</div>
        <div class="col-auto">
          <span class="badge border mx-2"
            >{{ match?.date_time | date : "shortTime" }}
          </span>
        </div>
        <div class="col">{{ match?.sk_teamB?.name }}</div>
      </div>
    </button>
  </h2>
  <div
    [id]="'collapse' + match?.xid"
    class="accordion-collapse collapse"
    [attr.aria-labelledby]="'heading' + match?.xid"
    [class.show]="match?.xid === latestMatchId"
    data-bs-parent="#fixtureaccordion"
  >
    <div class="accordion-body">
      <div class="accordion-content">
        <div class="row small">
          <div class="col">
            <!--            <i class="fas fa-map-marker-alt"></i> Kasarani Stadium-->
          </div>
          <div class="col-auto">
            <!-- <i class="fas fa-hand-paper"></i> GRAHAM SCOTT-->
            <i class="fas fa-map-marker-alt"></i> {{ match?.venue?.name }}
          </div>
          <div class="col">
            <!--<i class="fas fa-sun"></i> SUNNY-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
