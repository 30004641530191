import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CardMatchRecordsComponent } from '../shared/card-match-records/card-match-records.component';

@Component({
  selector: 'app-completed-match-card',
  standalone: true,
  imports: [CommonModule, CardMatchRecordsComponent],
  templateUrl: './completed-match-card.component.html',
  styleUrl: '../../fixtures.css',
})
export class CompletedMatchCardComponent {
  @Input() match!: any;

  @Input() latestMatchId!: any;
  showft = true
}
