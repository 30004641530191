<!-- Footer -->
<footer
  class="site-footer footer-overlay bg-img-fix footer-skew"
  style="
    background-image: url(images/background/bg5.jpg);
    background-position: center bottom;
    background-size: cover;
    display: block;
  "
>
  <div class="footer-top">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-sm-12 footer-col-12">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 m-b30 text-white">
                <h2 class="m-t0 font-40 m-b40 text-center">
                  <img
                    src="assets/images/sakajalogo.png"
                    width="193"
                    height="89"
                    alt=""
                  />
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- footer bottom part -->
  <div class="footer-bottom">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 text-left"><span>© Copyright 2024</span></div>
        <div class="col-lg-4 text-center">
          <span>
            Design With <i class="fa fa-heart text-red heart"></i> By
            sakajasupercup
          </span>
        </div>
        <div class="col-lg-4 text-right">
          <a> About</a> <a> Help Desk</a>
          <a> Privacy Policy</a>
        </div>
      </div>
    </div>
  </div>
</footer>
<!-- Footer END-->
