@if (record?.team?.xid == record?.match?.sk_teamA?.xid) { @if (record?.type
=="yellow_card") {
<div class="row small">
  <div class="col">
    {{ record?.team_member?.first_name }} {{ record?.team_member?.last_name }}
  </div>
  <div class="col-auto">
    <span class="yellow-card"></span>&nbsp;{{ record?.time_occured }}'
  </div>
  <div class="col"></div>
</div>
}@else if (record?.type == "red_card") {
<div class="row small">
  <div class="col">
    {{ record?.team_member?.first_name }} {{ record?.team_member?.last_name }}
  </div>
  <div class="col-auto">
    <span class="red-card"></span>&nbsp;{{ record?.time_occured }}'
  </div>
  <div class="col"></div>
</div>
}@else if (record?.type == "score") {
<div class="row small">
  <div class="col">
    {{ record?.team_member?.first_name }} {{ record?.team_member?.last_name }}
  </div>
  <div class="col-auto">
    <span class="fas fa-futbol icon-black "></span>&nbsp;{{ record?.time_occured }}'
  </div>
  <div class="col"></div>
</div>
} }@else if (record?.team?.xid == record?.match?.sk_teamB?.xid) { @if
(record?.type == "yellow_card") {
<div class="row small">
  <div class="col"></div>
  <div class="col-auto">
    <span class="yellow-card"></span>&nbsp;{{ record?.time_occured }}'
  </div>
  <div class="col">
    {{ record?.team_member?.first_name }} {{ record?.team_member?.last_name }}
  </div>
</div>
}@else if (record?.type == "red_card") {
<div class="row small">
  <div class="col"></div>
  <div class="col-auto">
    <span class="red-card"></span>&nbsp;{{ record?.time_occured }}'
  </div>
  <div class="col">
    {{ record?.team_member?.first_name }} {{ record?.team_member?.last_name }}
  </div>
</div>
}@else if (record?.type == "score") {
<div class="row small">
  <div class="col"></div>
  <div class="col-auto">
    <span class="fas fa-futbol icon-black "></span>&nbsp;{{ record?.time_occured }}'
  </div>
  <div class="col">
    {{ record?.team_member?.first_name }} {{ record?.team_member?.last_name }}
  </div>
</div>
} }@else {}
