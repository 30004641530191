<div class="accordion-item mb-2">
  <h2 class="accordion-header" [id]="'heading' + match?.xid">
    <button
      class="btn btn-accordion-finished collapsed font-rowdies w-100"
      type="button"
      data-bs-toggle="collapse"
      [attr.data-bs-target]="'#collapse' + match?.xid"
      aria-expanded="false"
      [attr.aria-controls]="'collapse' + match?.xid"
      [attr.aria-expanded]="match?.xid === latestMatchId ? 'true' : 'false'"
      [class.collapsed]="match?.xid !== latestMatchId"
    >
      <div class="row">
        <div class="col">{{ match?.sk_teamA?.name }}</div>
        <div class="col-auto">
          <span
            class="badge border mx-2"
            style="background-color: #d6181f !important; color: #fff !important"
            >{{ match?.team_a_score }} - {{ match?.team_b_score }}</span
          >
        </div>
        <div class="col">{{ match?.sk_teamB?.name }}</div>
        FT
      </div>
    </button>
  </h2>
  <div
    [id]="'collapse' + match?.xid"
    class="accordion-collapse collapse"
    [attr.aria-labelledby]="'heading' + match?.xid"
    [class.show]="match?.xid === latestMatchId"
    data-bs-parent="#fixtureaccordion"
  >
    <div class="accordion-body">
      <div class="badge bg-finished rounded-bottom">
        <small>FULL TIME</small>
      </div>

      <div class="accordion-content">
        @for (live_update of match?.live_updates; track $index) {
        <app-card-match-records [record]="live_update"></app-card-match-records>
        }
      </div>
    </div>
  </div>
</div>
