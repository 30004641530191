<h1
  style="
    margin-top: 20vh;
    margin-bottom: 0pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: 115%;
    font-size: 14pt;
  "
>
  <span style="font-family: Calibri; color: #365f91"
    >Privacy Policy for Sakaja SuperCup</span
  >
</h1>
<p style="margin-top: 0pt; margin-bottom: 10pt">
  Sakaja SuperCup (&apos;we,&apos; &apos;our,&apos; or &apos;us&apos;) is
  committed to protecting your personal information and ensuring your privacy.
  This Privacy Policy explains how we collect, use, disclose, and safeguard your
  information when you participate in or interact with the Sakaja SuperCup
  tournament, which operates under FIFA rules, FKF, and KPL regulations.
</p>
<h2
  style="
    margin-top: 10pt;
    margin-bottom: 0pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: 115%;
    font-size: 13pt;
  "
>
  <span style="font-family: Calibri; color: #4f81bd"
    >1. Scope of This Policy</span
  >
</h2>
<p style="margin-top: 0pt; margin-bottom: 10pt">
  This Privacy Policy applies to all personal information collected, used, or
  shared by Sakaja SuperCup in connection with the tournament, whether through
  our website, mobile app, or any other forms of electronic and physical
  communications.
</p>
<h2
  style="
    margin-top: 10pt;
    margin-bottom: 0pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: 115%;
    font-size: 13pt;
  "
>
  <span style="font-family: Calibri; color: #4f81bd"
    >2. Information We Collect</span
  >
</h2>
<h3
  style="
    margin-top: 10pt;
    margin-bottom: 0pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    font-size: 11pt;
  "
>
  <span style="font-family: Calibri; color: #4f81bd"
    >a) Personal Information</span
  >
</h3>
<p style="margin-top: 0pt; margin-bottom: 10pt">
  We collect the following types of personal information from participants,
  coaches, officials, volunteers, and attendees:<br />- Identification
  Information: Name, age, gender, date of birth, and nationality.<br />- Contact
  Information: Phone number, email address, and physical address.<br />- Player
  and Performance Data: Player statistics, game performance, health and fitness
  data, where applicable.<br />- Sensitive Information: Where required by FIFA,
  FKF, or KPL, we may collect medical information, disciplinary records, or
  consent for video/photographic use.
</p>
<h3
  style="
    margin-top: 10pt;
    margin-bottom: 0pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    font-size: 11pt;
  "
>
  <span style="font-family: Calibri; color: #4f81bd">b) Technical Data</span>
</h3>
<p style="margin-top: 0pt; margin-bottom: 10pt">
  When you visit our website or use our app, we may collect technical data such
  as:<br />- IP address, device information, location data, browsing history,
  and usage details for analytical purposes.
</p>
<h2
  style="
    margin-top: 10pt;
    margin-bottom: 0pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: 115%;
    font-size: 13pt;
  "
>
  <span style="font-family: Calibri; color: #4f81bd"
    >3. How We Use Your Information</span
  >
</h2>
<h3
  style="
    margin-top: 10pt;
    margin-bottom: 0pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    font-size: 11pt;
  "
>
  <span style="font-family: Calibri; color: #4f81bd"
    >a) Tournament Management</span
  >
</h3>
<p style="margin-top: 0pt; margin-bottom: 10pt">
  To organize, manage, and ensure compliance with tournament regulations, we use
  your data to:<br />- Verify eligibility for participation.<br />- Register and
  categorize players into appropriate teams and leagues.<br />- Track player
  performance and health status during matches.
</p>
<h3
  style="
    margin-top: 10pt;
    margin-bottom: 0pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    font-size: 11pt;
  "
>
  <span style="font-family: Calibri; color: #4f81bd"
    >b) Compliance with Rules and Regulations</span
  >
</h3>
<p style="margin-top: 0pt; margin-bottom: 10pt">
  As mandated by FIFA, FKF, and KPL, we:<br />- Report data for governance,
  regulatory, and disciplinary purposes.<br />- Share information with relevant
  organizations to ensure adherence to game integrity and ethical standards.
</p>
<h3
  style="
    margin-top: 10pt;
    margin-bottom: 0pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    font-size: 11pt;
  "
>
  <span style="font-family: Calibri; color: #4f81bd"
    >c) Communication and Marketing</span
  >
</h3>
<p style="margin-top: 0pt; margin-bottom: 10pt">
  With your consent, we may send updates, promotional content, and other
  marketing information regarding Sakaja SuperCup and future events. You may opt
  out of these communications at any time.
</p>
<h3
  style="
    margin-top: 10pt;
    margin-bottom: 0pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    font-size: 11pt;
  "
>
  <span style="font-family: Calibri; color: #4f81bd">d) Legal Compliance</span>
</h3>
<p style="margin-top: 0pt; margin-bottom: 10pt">
  Where required, we will process personal information in accordance with
  applicable Kenyan law and the regulatory requirements of FIFA, FKF, and KPL.
</p>
<h2
  style="
    margin-top: 10pt;
    margin-bottom: 0pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: 115%;
    font-size: 13pt;
  "
>
  <span style="font-family: Calibri; color: #4f81bd"
    >4. Sharing Your Information</span
  >
</h2>
<p style="margin-top: 0pt; margin-bottom: 10pt">
  We may share your information under the following circumstances:<br />- With
  FIFA, FKF, and KPL: To fulfill regulatory reporting requirements, address
  compliance issues, or in response to disciplinary inquiries.<br />- Service
  Providers: Third-party vendors assisting with registration, ticketing, data
  analytics, or communications.<br />- Law Enforcement: When necessary to comply
  with legal obligations or to protect the rights, property, and safety of
  participants, staff, and the general public.
</p>
<h2
  style="
    margin-top: 10pt;
    margin-bottom: 0pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: 115%;
    font-size: 13pt;
  "
>
  <span style="font-family: Calibri; color: #4f81bd">5. Data Security</span>
</h2>
<p style="margin-top: 0pt; margin-bottom: 10pt">
  We employ best practices and security measures, such as encryption, access
  controls, and regular monitoring, to protect your information. Despite these
  efforts, no security measure is foolproof. If you suspect unauthorized access
  to your information, please contact us immediately.
</p>
<h2
  style="
    margin-top: 10pt;
    margin-bottom: 0pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: 115%;
    font-size: 13pt;
  "
>
  <span style="font-family: Calibri; color: #4f81bd">6. Retention of Data</span>
</h2>
<p style="margin-top: 0pt; margin-bottom: 10pt">
  Your personal information will be retained only as long as necessary for the
  purpose it was collected or to comply with regulatory requirements. We will
  securely delete or anonymize data that is no longer required for legal or
  operational purposes.
</p>
<h2
  style="
    margin-top: 10pt;
    margin-bottom: 0pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: 115%;
    font-size: 13pt;
  "
>
  <span style="font-family: Calibri; color: #4f81bd"
    >7. Rights Over Your Data</span
  >
</h2>
<p style="margin-top: 0pt; margin-bottom: 10pt">
  You have the following rights regarding your data:<br />- Access: Request
  access to your personal information.<br />- Correction: Correct or update
  inaccurate or incomplete information.<br />- Deletion: Request deletion of
  your data, subject to any legal retention requirements.<br />- Objection:
  Object to processing for direct marketing or other purposes.<br />- Data
  Portability: Request a copy of your data in a structured, machine-readable
  format.
</p>
<h2
  style="
    margin-top: 10pt;
    margin-bottom: 0pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: 115%;
    font-size: 13pt;
  "
>
  <span style="font-family: Calibri; color: #4f81bd"
    >8. Minors&rsquo; Privacy</span
  >
</h2>
<p style="margin-top: 0pt; margin-bottom: 10pt">
  Participants under the age of 18 require parental or guardian consent to
  participate in the tournament and to process their personal information. We
  ensure that all minors&rsquo; data is handled with special consideration for
  privacy and security.
</p>
<h2
  style="
    margin-top: 10pt;
    margin-bottom: 0pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: 115%;
    font-size: 13pt;
  "
>
  <span style="font-family: Calibri; color: #4f81bd"
    >9. Updates to This Privacy Policy</span
  >
</h2>
<p style="margin-top: 0pt; margin-bottom: 10pt">
  We may update this Privacy Policy periodically to reflect regulatory or
  operational changes. Changes will be posted on our website, and significant
  updates will be communicated through appropriate channels.
</p>
<h2
  style="
    margin-top: 10pt;
    margin-bottom: 0pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: 115%;
    font-size: 13pt;
  "
>
  <span style="font-family: Calibri; color: #4f81bd"
    >10. Contact Information</span
  >
</h2>
<p style="margin-top: 0pt; margin-bottom: 10pt">
  For questions or concerns regarding this Privacy Policy, please contact:<br /><br />Sakaja
  SuperCup Privacy Officer<br />Nairobi City Hall, City Hall Way, Nairobi,
  Kenya<br />Telephone Contacts +254 725 624 489, +254 738 041 292<br />goals&#64;sakajasupercup.com
</p>
<h2
  style="
    margin-top: 10pt;
    margin-bottom: 0pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: 115%;
    font-size: 13pt;
  "
>
  <span style="font-family: Calibri; color: #4f81bd"
    >11. Acknowledgment and Consent</span
  >
</h2>
<p style="margin-top: 0pt; margin-bottom: 10pt">
  By participating in Sakaja SuperCup or using our
  <a href="sakajasupercup.com" style="text-decoration: none"
    ><u><span style="color: #0000ff">website</span></u></a
  >/app, you agree to the collection, use, and disclosure of your information as
  outlined in this Privacy Policy.
</p>
