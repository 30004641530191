import { Component } from '@angular/core';

@Component({
  selector: 'app-search-fixtures',
  standalone: true,
  imports: [],
  templateUrl: './search-fixtures.component.html',
  styleUrl: './search-fixtures.component.css'
})
export class SearchFixturesComponent {

}
