import { CommonModule } from '@angular/common';
import { Component, Input, input } from '@angular/core';

@Component({
  selector: 'app-upcoming-match-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './upcoming-match-card.component.html',
  styleUrl: '../../fixtures.css',
})
export class UpcomingMatchCardComponent {
  @Input() match!: any;
  @Input() latestMatchId!: any;
  x = [
    {
      id: 1,
      sk_teamA: {
        id: 3,
        name: 'PUMWANI SPORTS CLUB',
        slug: 'pumwani-sports-club',
      },
      sk_teamB: {
        id: 4,
        name: 'YACH 009',
        slug: 'yach-009',
      },
      venue: {
        id: 1,
        name: 'Nairobi Stadium',
        slug: 'nairobi-stadium',
      },
      date_time: '2024-10-18T14:30:00Z',
      status: 'upcoming',
    },
    {
      id: 2,
      sk_teamA: {
        id: 3,
        name: 'PUMWANI SPORTS CLUB',
        slug: 'pumwani-sports-club',
      },
      sk_teamB: {
        id: 4,
        name: 'YACH 009',
        slug: 'yach-009',
      },
      venue: {
        id: 1,
        name: 'Nairobi Stadium',
        slug: 'nairobi-stadium',
      },
      date_time: '2024-10-18T14:30:00Z',
      status: 'live',
    },
  ];
}
