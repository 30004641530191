import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgxDaterangepickerMd.forRoot()

  ],
  declarations: [
  ],
  exports: [
    CommonModule,
    FormsModule,
  ],
})
export class SharedModule { }
