import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-match-records',
  standalone: true,
  imports: [],
  templateUrl: './card-match-records.component.html',
  styleUrl: '../../../fixtures.css',
})
export class CardMatchRecordsComponent {
  @Input() record!: any;
}
